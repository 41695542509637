import router from "../router/index";
import services from "../services";
import { prepareAndCommitData } from "../analysisHelpers";
import i18n from "../i18n";

const strict = true;
const analysisService = services.AnalysisService;

const defaultState = () => {
  return {
    jsonVersion: 6,
    guestMode: false,
    display: "grid",
    videoData: [],
    videoIndex: 0,
    videoIsPlaying: false,
    selectedEmotion: "happiness",
    selectedMetric: "attention",
    selectedDemographic: "gender",
    selectedSegment: 0,
    selectedQuestion: 0,
    selectedTab: 0,
    activeChart: "curves",
    filters: {
      all: true,
      trend: false,
      happiness: false,
      surprise: false,
      anger: false,
      disgust: false,
      fear: false,
      sadness: false,
      engagement: false,
      valence: false,
      attention: false,
      male: false,
      female: false,
      other: false,
      agerange1: false,
      agerange0: false,
      positive: false,
      negative: false
    },
    comparisonData: [],
    projectName: "",
    projectQuestions: [],
    questions: [],
    ageRanges: [],
    customSegments: [],
    genderInfo: [],
    norms: {
      attention: 0,
      attentionSpan: 0,
      digitalPerformance: 0,
      effectiveness: 0,
      engagement: 0,
      globalPerformance: 0
    },
    errors: []
  };
};

const state = defaultState();

const getters = {
  isGuestMode: state => state.guestMode,

  comparison: state => {
    return state.videoIndex === -1;
  },

  engagementNorm: state => {
    if (state.filters.engagement) {
      return state.norms.engagement;
    }
    return null;
  },

  attentionSpan: state => {
    if (state.videoData[state.videoIndex]) {
      return state.videoData[state.videoIndex].attentionSpan;
    }
    return null;
  },

  ageRanges: state => {
    if (state.ageRanges[state.videoIndex]) {
      return state.ageRanges[state.videoIndex];
    }
    return [];
  },

  attentionSpanNorm: state => {
    if (state.filters.attention) {
      return state.norms.attentionSpan;
    }
    return null;
  },

  video: state => {
    if (state.videoIndex !== -1) {
      return state.videoData[state.videoIndex];
    }
    return state.videoData[0];
  },

  isIncontext: (state, getters) => {
    const video = getters.video; 
    return !!video?.in_context_metrics
  },
  graphTitle: (state, getters) => {
    if (state.selectedTab === 2 && !getters.comparison) {
      if (state.selectedMetric === "engagement") {
        return i18n.t("page.results.emotionAnalyticsLabels.engagement");
      } else if (state.selectedMetric === "attention") {
        return i18n.t("page.results.emotionAnalyticsLabels.attention");
      } else {
        return i18n.t("page.results.emotionAnalytics");
      }
    } else if (state.filters.engagement) {
      return i18n.t("page.results.emotionAnalyticsLabels.engagement");
    } else if (state.filters.attention) {
      return i18n.t("page.results.emotionAnalyticsLabels.attention");
    } else if (state.filters.valence) {
      return i18n.t("page.results.valence");
    } else {
      return i18n.t("page.results.emotionAnalytics");
    }
  },

  answers: state => {
    if (state.questions[state.videoIndex][state.selectedQuestion]) {
      return state.questions[state.videoIndex][state.selectedQuestion].answers;
    }
    return [];
  },

  valence: (state, getters) => {
    const valence = getters.video.valence;
    if (
      typeof valence === "string" &&
      (valence === "positive" || valence === "negative")
    ) {
      return valence;
    } else if (typeof valence === "number") {
      if (valence < 0) {
        return "negative";
      } else {
        return "positive";
      }
    }
    return "error";
  },

  valenceText: (state, getters) => {
    const valence = getters.video.valence;
    if (typeof valence === "string") {
      if (valence === "negative") {
        return i18n.t("page.results.score.valence.textNegative");
      } else if (valence === "positive") {
        return i18n.t("page.results.score.valence.textPositive");
      }
    } else if (typeof valence === "number") {
      if (valence < 0) {
        return i18n.t("page.results.score.valence.textNegative");
      } else {
        return i18n.t("page.results.score.valence.textPositive");
      }
    }
    return "error";
  },

  globalPerformanceTitle: (state, getters) => {
    const globalPerformance = getters.video.globalPerformance;
    const inContextMetrics = getters.video.in_context_metrics;
    const inContextEfficiency = (inContextMetrics) ? inContextMetrics.efficiency *100 : 0
    if(!inContextMetrics){
      if (state.jsonVersion < 6) {
        if (globalPerformance < 4) {
          return i18n.t("page.results.score.globalPerformance.old.low.title");
        } else if (globalPerformance < 7) {
          return i18n.t("page.results.score.globalPerformance.old.average.title");
        } else {
          return i18n.t("page.results.score.globalPerformance.old.high.title");
        }
      } else {
        if (globalPerformance < 40) {
          return i18n.t("page.results.score.globalPerformance.low.title");
        } else if (globalPerformance < 70) {
          return i18n.t("page.results.score.globalPerformance.average.title");
        } else {
          return i18n.t("page.results.score.globalPerformance.high.title");
        }
      }
    }else{
      if (inContextEfficiency < 95) {
        return i18n.t("page.results.score.incontextMetrics.low.title");
      } else if (inContextEfficiency < 105) {
        return i18n.t("page.results.score.incontextMetrics.average.title");
      } else {
        return i18n.t("page.results.score.incontextMetrics.high.title");
      }
    }
  },

  globalPerformanceText: (state, getters) => {
    const globalPerformance = getters.video.globalPerformance;
    const inContextMetrics = getters.video.in_context_metrics;
    const inContextEfficiency = (inContextMetrics) ? inContextMetrics.efficiency *100 : 0

    if(!inContextMetrics){
      if (state.jsonVersion < 6) {
        if (globalPerformance < 4) {
          return i18n.t("page.results.score.globalPerformance.old.low.text");
        } else if (globalPerformance < 7) {
          return i18n.t("page.results.score.globalPerformance.old.average.text");
        } else {
          return i18n.t("page.results.score.globalPerformance.old.high.text");
        }
      } else {
        if (globalPerformance < 40) {
          return i18n.t("page.results.score.globalPerformance.low.text");
        } else if (globalPerformance < 70) {
          return i18n.t("page.results.score.globalPerformance.average.text");
        } else {
          return i18n.t("page.results.score.globalPerformance.high.text");
        }
      }
    }else{
        if (inContextEfficiency < 95) {
          return i18n.t("page.results.score.incontextMetrics.low.text");
        } else if (inContextEfficiency < 105) {
          return i18n.t("page.results.score.incontextMetrics.average.text");
        } else {
          return i18n.t("page.results.score.incontextMetrics.high.text");
        }
    }
  },

  globalPerformanceResponse: (state, getters) => {
    const globalPerformance = getters.video.globalPerformance;
    if (state.jsonVersion < 6) {
      if (globalPerformance < 4) {
        return "LOW";
      } else if (globalPerformance < 7) {
        return "AVERAGE";
      } else {
        return "HIGH";
      }
    } else {
      if (globalPerformance < 40) {
        return "LOW";
      } else if (globalPerformance < 70) {
        return "AVERANGE";
      } else {
        return "HIGH";
      }
    }
  },

  globalPerformanceColor: (state, getters) => {
    const globalPerformance = getters.video.globalPerformance;
    if (state.jsonVersion < 6) {
      if (globalPerformance < 4) {
        return "red";
      } else if (globalPerformance < 7) {
        return "var(--orange)";
      } else {
        return "var(--green)";
      }
    } else {
      if (globalPerformance < 40) {
        return "red";
      } else if (globalPerformance < 70) {
        return "var(--orange)";
      } else {
        return "var(--green)";
      }
    }
  },

  selectedSegment: state => {
    if (state.selectedDemographic.includes("custom"))
      return state.selectedDemographic.split("-")[1];
  },

  chartData: (state, getters) => {
    let data = [];

    if (getters.comparison) {
      return state.comparisonData.data[state.selectedEmotion];
    }

    if (state.filters.attention) {
      if (state.videoData.length > 0) {
        if (!state.comparison) {
          const attentionChart =
            state.videoData[state.videoIndex].attentionChart;
          if (attentionChart) {
            return attentionChart.data;
          }
          return [];
        }
      }
    }

    if (state.videoData.length > 0) {
      switch (state.selectedTab) {
        case 0:
          data = state.videoData[state.videoIndex].emotionChartData.data;
          break;
        case 1:
          if (state.selectedDemographic.includes("custom")) {
            const selectedSegment = state.selectedDemographic.split("-")[1];
            data =
              state.videoData[state.videoIndex].demographicsChartData.custom[
                selectedSegment
              ].data[state.selectedEmotion];
          } else {
            data =
              state.videoData[state.videoIndex].demographicsChartData[
                state.selectedDemographic
              ].data[state.selectedEmotion];
          }

          break;
        case 2: {
          const question = state.videoData[
            state.videoIndex
          ].segmentsChartData.questions.find(q => {
            return q.id === state.selectedQuestion;
          });

          const key =
            state.jsonVersion < 6
              ? state.selectedEmotion
              : state.selectedMetric;

          if (question) {
            data = question.data[key];
          } else {
            data =
              state.videoData[state.videoIndex].segmentsChartData.questions[
                state.selectedQuestion
              ].data[key];
          }
        }
      }
    }
    return data;
  },

  chartStyles: (state, getters) => {
    if (getters.comparison) {
      return state.comparisonData.styles;
    }

    if (state.filters.attention) {
      if (state.videoData.length > 0) {
        const attentionChart = state.videoData[state.videoIndex].attentionChart;
        if (attentionChart) {
          console.log(attentionChart);
          return attentionChart.styles;
        }
        return [];
      }
    }

    if (state.videoData.length > 0) {
      switch (state.selectedTab) {
        case 0:
          return state.videoData[state.videoIndex].emotionChartData.styles;
        case 1:
          if (state.selectedDemographic.includes("custom")) {
            const selectedSegment = state.selectedDemographic.split("-")[1];
            return state.videoData[state.videoIndex].demographicsChartData
              .custom[selectedSegment].styles;
          } else {
            return state.videoData[state.videoIndex].demographicsChartData[
              state.selectedDemographic
            ].styles;
          }
        case 2: {
          const question = state.videoData[
            state.videoIndex
          ].segmentsChartData.questions.find(q => {
            return q.id === state.selectedQuestion;
          });

          if (question) {
            return question.styles;
          } else {
            return state.videoData[state.videoIndex].segmentsChartData
              .questions[state.selectedQuestion].styles;
          }
        }
      }
    }
  },

  legendValues: (state, getters) => {
    const styles = getters.chartStyles;
    const styleEntries = Object.entries(styles)
      .filter(entry => !entry[0].includes("-trend"))
      .map((entry, index) => {
        const text = entry[1].text
          ? entry[1].text
          : !entry[0].includes("video")
          ? i18n.t(`page.results.emotionAnalyticsLabels.${entry[0]}`)
          : state.videoData[index].name;
        const count = entry[1].count;
        return [
          entry[0],
          {
            color: entry[1].color,
            text: `${text}`,
            count: `${count ? "(" + count + ")" : ""}`
          }
        ];
      });
    return Object.fromEntries(styleEntries);
  },

  answerPercentages: (_, getters) => {
    const chartStyles = getters.chartStyles;
    const answerKeys = Object.keys(chartStyles).filter(
      key => !key.includes("-trend")
    );
    const values = new Array(answerKeys.length).fill(0);
    answerKeys.forEach(key => {
      if (key.includes("answer")) {
        const count = chartStyles[key].count;
        const index = parseInt(key.split("answer")[1]);
        if (count && typeof count === "string" && count.includes("%")) {
          values[index] = parseFloat(count.split("%"));
        } else if (count && typeof count === "number") {
          values[index] = count;
        }
      }
    });
    return values;
  },

  barChartData: (state, getters) => {
    if (state.videoData.length > 0) {
      if (!getters.comparison) {
        return state.videoData[state.videoIndex].barChart.data;
      }
    }
  },
  videoDataLength: state => state.videoData.length,

  videoIndex: state => state.videoIndex,
  getCurrentVideoIndex: state => state.videoIndex,
};

const mutations = {
  RESET: state => {
    // state = defaultState();
    const s = defaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  SET_JSON_VERSION: (state, jsonVersion) => {
    state.jsonVersion = jsonVersion;
  },

  SET_VIDEO_IS_PLAYING: (state, isPlaying) => {
    state.videoIsPlaying = isPlaying;
  },

  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },

  SET_PROJECT_NAME: (state, name) => (state.projectName = name),

  SET_PROJECT_QUESTIONS: (state, questions) =>
    (state.projectQuestions = questions),

  SET_DISPLAY: (state, display) => (state.display = display),

  SET_STUDY: (state, study) => (state.study = study),

  SET_VIDEO_DATA: (state, videoData) => (state.videoData = videoData),

  SET_CURRENT_VIDEO_INDEX: (state, videoIndex) =>
    (state.videoIndex = videoIndex),

  SET_TAB: (state, tab) => {
    state.selectedTab = tab;
    state.chartIsLoading = true;
  },

  SET_GUEST_MODE: (state, guestMode) => (state.guestMode = guestMode),

  SET_EMOTION: (state, emotion) => {
    state.selectedEmotion = emotion;
    state.chartIsLoading = true;
  },

  SET_METRIC: (state, metric) => {
    state.selectedMetric = metric;
    state.chartIsLoading = true;
  },

  SET_DEMOGRAPHIC: (state, demographic) => {
    state.selectedDemographic = demographic;
    state.chartIsLoading = true;
  },

  SET_QUESTION: (state, question) => {
    state.selectedQuestion = question;
    state.chartIsLoading = true;
  },

  SET_ACTIVE_CHART: (state, chart) => (state.activeChart = chart),

  SET_FILTERS: (state, filters) => (state.filters = Object.assign({}, filters)),

  SET_COMPARISON_DATA: (state, data) => (state.comparisonData = data),

  SET_QUESTIONS: (state, questions) => (state.questions = questions),

  SET_AGE_RANGES: (state, ageRanges) => (state.ageRanges = ageRanges),

  SET_GENDER_INFO: (state, genderInfo) => (state.genderInfo = genderInfo),

  SET_CUSTOM_SEGMENTS: (state, customSegments) =>
    (state.customSegments = customSegments),

  SET_NORMS: (state, norms) => (state.norms = norms)
};

const actions = {
  async retrieveNorms({ commit }) {
    services.AnalysisService.retrieveNorms()
      .then(data => {
        commit("SET_NORMS", data.norms);
      })
      .catch(error => {
        console.error(error);
        checkRedirectionByStatus(error.status, commit);
        return Promise.reject(error);
      });
  },

  async retrieveAnalysisData(store, params) {
    const p1 = services.AnalysisService.retrieveAnalysisData({
      clientId: params.clientId,
      projectId: params.projectId
    });
    const p2 = services.ProjectService.retrieveProjectAssets(params.projectId);
    const p3 = services.ProjectService.retrieveProjectByClientId(
      params.clientId,
      params.projectId
    );

    store.commit("SET_CURRENT_VIDEO_INDEX", 0);
    store.commit("SET_TAB", 0);
    store.commit("SET_QUESTION", 0);

    return Promise.all([p1, p2, p3])
      .then(values => {
        const analysisDataRaw = values[0];
        const projectAssets = values[1];
        const project = values[2];

        prepareAndCommitData(store, analysisDataRaw, project, projectAssets);
      })
      .catch(error => {
        console.error(error);
        checkRedirectionByStatus(error.status, store.commit);
        return Promise.reject(error);
      });
  },

  async retrieveShareLink({ commit }, { clientId, projectId }) {
    return services.AnalysisService.retrieveSharingToken({
      clientId,
      projectId
    });
  },

  async retrieveVideoDataBySharingToken(store, params) {
    try {
      const response = await analysisService.retrieveVideoDataBySharingToken({
        sharingToken: params.sharingToken,
        projectId: params.projectId,
        clientId: params.clientId
      });

      const analysisDataRaw = response.project.analysis;
      const projectAssets = response.assets;
      const project = response.project;

      prepareAndCommitData(store, analysisDataRaw, project, projectAssets);
    } catch (error) {
      console.log(error);
      checkRedirectionByStatus(error.status, store.commit);
      return Promise.reject(error);
    }
  }
};

function checkRedirectionByStatus(status, commit) {
  if (status === 401) {
    commit("auth/SET_IS_LOGGED_IN", false, { root: true });
    commit(
      "error/SET_ERROR",
      { status },
      {
        root: true
      }
    );
    router.replace({ name: "Login", params: { is404: true } }).catch(() => {});
  } else if (status === 404 || status === 405) {
    commit(
      "error/SET_ERROR",
      { status },
      {
        root: true
      }
    );
    router
      .replace({ name: "Dashboard", params: { is404: true } })
      .catch(() => {});
  } else {
    commit(
      "error/SET_ERROR",
      { status },
      {
        root: true
      }
    );
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
